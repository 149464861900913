<template>
  <div class="general-report-by-flat" style="height: 100%">
    <v-menu
        v-model="showExpand"
        :position-x="x"
        :position-y="y"
        absolute
        offset-y
        class="non-printable"
    >
      <v-list nav dense min-width="140"
              class="non-printable"
      >
        <v-list-item @click="toggleExpand(-1)">
          <v-list-item-title>Усі рівні</v-list-item-title>
        </v-list-item>
        <v-list-item
            v-for="(item, index) in max_lvl+1"
            :key="`index-menu-${index}`"
            @click="toggleExpand(item-1)"
        >
          <v-list-item-title>{{ `Рівень ${item-1}` }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-navigation-drawer
        class="non-printable"
        app
        :width="navWidth"
        right
        v-model="setting_dialog"
        temporary
        @input="onNavInput"
        style="display: none"
    >
      <v-btn
          @click="closeSettings"
          depressed
      >Приховати налаштування
      </v-btn>

      <v-row>
        <v-col cols="12">
          <v-card tile elevation="0">
            <v-card-text class="pt-2">
              <v-tabs fixed-tabs color="grey darken-2">
                <v-tab>Основні</v-tab>
                <v-tab>Групування</v-tab>
                <v-tab-item class="mt-3">
                </v-tab-item>
                <v-tab-item class="mt-3">
                </v-tab-item>
              </v-tabs>

            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-navigation-drawer>
    <div class="page page-a4" style="height: auto !important; min-height: 215mm"
         :style="`zoom: ${scale / 100}`"
    >
      <div class="report px-8" :style="`transform: scale(${zoom}); transform-origin: top left;`"
           style="height: 100%">
        <div class="report-section flex-wrap">
          <div class="rp-col-12 font-weight-bold text-center">
            Угода №<span style="text-decoration: underline"> &nbsp;&nbsp;&nbsp;{{ item.number || '   ' }}&nbsp;&nbsp;&nbsp;</span>
          </div>
          <div class="rp-col-12 text-center">
            на погашення заборгованості за {{ service_name }}
          </div>
        </div>
        <div class="report-section pt-1 pb-2">
          <div class="rp-col-6 font-weight-bold">
            {{ (flat.full_address.split(',') || [])[0] }}
          </div>
          <div class="rp-col-6 font-weight-bold text-right">
            {{ item.date | formatDate('DD MMMM YYYY') }} р.
          </div>
        </div>
        <div class="report-section">
          <div class="rp-col-12 text-justify">
            {{ organization.short_name }} (далі - "Підприємство") в особі {{ director }} , що діє на підставі Статуту, з одного боку та
            {{ item.agreement_owner }} (далі - "Домовласник"), який є власником (наймачем) житлового приміщення, з іншого боку
            уклали цей договір про наступне:
          </div>
        </div>

        <div class="report-section py-1">
          <div class="rp-col-12 text-center font-weight-bold text-uppercase">
            1. Предмет договору
          </div>
        </div>

        <div class="report-section">
          <div class="rp-col-12 text-justify">
            1.1. Предметом  угоди  є  погашення  заборгованості за {{ service_name }}, яка  станом на
            {{ item.date | formatDate('DD MMMM YYYY') }} р., яка складає {{ item.debt_sum | formatToFixed }} грн.
          </div>
        </div>

        <div class="report-section py-1">
          <div class="rp-col-12 text-center font-weight-bold text-uppercase">
            2. Права та обов'язки
          </div>
        </div>

        <div class="report-section flex-wrap">
          <div class="rp-col-12 text-justify">
            2.1. "Домовласник" зобов'язується:
          </div>
          <div class="rp-col-12 text-justify">
            2.1.1. На протязі {{ item.months }} місяців погасити заборгованість за {{ service_name }} в сумі
            <span style="text-decoration: underline; padding-left: 4px; padding-right: 4px; font-weight: bold">
              {{ item.debt_sum | formatToFixed }}
            </span> грн. відповідно сплачуючи щомісячно згідно графіка наведеного нижче:
          </div>
        </div>

        <div class="report-section">
          <div class="report-table pt-2">
            <div class="report-table-row rp-font-weight-medium">
              <div class="rp-col-2">
                <span class="table-col-content">№ з/п</span>
              </div>
              <div class="rp-col-5">
                <span class="table-col-content">Місяць</span>
              </div>
              <div class="rp-col-5">
                <span class="table-col-content">Сума</span>
              </div>
            </div>
            <div
                class="report-table-row"
                v-for="(row, index) in item.pay_table || []"
                :key="`tr-${index}`"
            >
              <div class="rp-col-2">
                <span class="table-col-content">{{ index + 1 }}</span>
              </div>
              <div class="rp-col-5">
                <span class="table-col-content text-capitalize"
                      style="justify-content: left; padding-left: 10px">{{ row.month | formatDate('MMMM YYYY') }}</span>
              </div>
              <div class="rp-col-5">
                <span class="table-col-content">{{ row.sum  | formatToFixed }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="report-section flex-wrap pt-2">
          <div class="rp-col-12 text-justify">
            2.1.2. Щомісячно, до {{ debt_pay_day }} числа сплачувати вартість наданих послуг за
            попередній місяць та місячну суму заборгованості.
          </div>
          <div class="rp-col-12 text-justify">
            2.1.2. У разі розірвання договору при відчуженні житлового приміщення сплатити
            не внесену суму розстроченого боргу та суму нарахованої пені (за її наявності)
          </div>
          <div class="rp-col-12 text-justify">
            2.2. "Домовласник" має право: <br>
            <div class="pl-2">
              - достроково погасити борг в повному обсязі або його частину;
            </div>
            <div class="pl-2">
              - достроково розірвати договiр
            </div>
            <div class="pl-2">
              - вимагати проведення перерахунку реструктурованого боргу у зв’язку із виявленими помилками з боку "Підприємства".
            </div>
          </div>
          <div class="rp-col-12 text-justify">
            2.3. "Підприємство" зобов’язується:
          </div>
          <div class="rp-col-12 text-justify">
            2.3.1. У разі виявлених помилок при обчислені суми заборгованості провести перерахунок суми реструктурованого боргу
          </div>
          <div class="rp-col-12 text-justify">
            2.3.2.  У разі дострокового погашення боргу приймати  рiшення про дострокове припинення дії  договору.
          </div>
          <div class="rp-col-12 text-justify">
            2.4. "Підприємство" має право приймати рiшення про дострокове розiрвання договору про реструктуризацію у разі:
            <br>
            <div class="pl-2">
              - дострокового погашення реструктуризованої заборгованості;
            </div>
            <div class="pl-2">
              - відчуження громадянином житлового приміщення;
            </div>
            <div class="pl-2">
              - подання громадянином заяви  про розірвання договору;
            </div>
            <div class="pl-2">
              - подання громадянином документів з недостовірними відомостями.
            </div>
          </div>
        </div>

        <div class="report-section py-1">
          <div class="rp-col-12 text-center font-weight-bold text-uppercase">
            3. Відповідальність сторін
          </div>
        </div>

        <div class="report-section flex-wrap">
          <div class="rp-col-12 text-justify">
            3.1. За повне або часткове нездійснення платежів згідно з графіком (п. 2.1.1.) погашення
            заборгованості у розмірі, що дорівнює сумі двомісячних платежів, кредитор має право достроково розірвати
            договір в односторонньому порядку, здійснювати заходи щодо дострокового стягнення непогашеної суми
            заборгованості згідно з договором, нараховувати на непогашену суму заборгованості неустойку
            (штраф, пеню), проценти річних, інфляційні нарахування.
          </div>
          <div class="rp-col-12 text-justify">
            3.2. Суперечки по цій Угоді вирішуються сторонами в добровільному порядку, а при недосягненні згоди - в суді.
          </div>
        </div>

        <div class="report-section py-1">
          <div class="rp-col-12 text-center font-weight-bold text-uppercase">
            4. Термін дії угоди
          </div>
        </div>

        <div class="report-section flex-wrap">
          <div class="rp-col-12 text-justify">
            4.1. Ця угода набирає чинності з дня її підписання сторонами і діє до виконання прийнятих по ній зобов’язань.
          </div>
        </div>

        <div class="report-section py-1">
          <div class="rp-col-12 text-center font-weight-bold text-uppercase">
            5. Юридичні адреси сторін
          </div>
        </div>

        <div class="report-section">
            <div class="rp-col-5">
              <div class="text-uppercase font-weight-bold text-center pb-1">Постачальник:</div>
              <div>
                {{ print_data.organization_short_name }}, {{ print_data.organization_details_for_document }}
              </div>
            </div>
            <div class="rp-col-2"></div>
            <div class="rp-col-5">
              <div class="text-uppercase font-weight-bold text-center pb-1">Домовласник:</div>
              <div style="border-bottom: 1px solid grey">
                {{ item.agreement_owner }}
              </div>
              <div style="border-bottom: 1px solid grey">
                Адреса: {{ flat.full_address.substr(0, 60) }}
              </div>
              <div style="border-bottom: 1px solid grey; min-height: 19px">
                {{ flat.full_address.substr(61) }}
              </div>
              <div style="border-bottom: 1px solid grey">
                Підпис:
              </div>
            </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>

import {formatDate} from "@/filters";
import reportAPI from "@/utils/axios/reports"
import {ALERT_SHOW} from "@/store/actions/alert";
import {getCurrentDate, getPrintDateRange, isExpanded, LANDSCAPE_ZOOM_WIDTH} from "@/utils/reports";
import {mapGetters} from "vuex";


export default {
  name: "restructuring_agreement",
  props: ['organization', 'print_data', 'global_settings', 'scale', 'date_start', 'date_end', 'generate',
    'open_all', 'generate_xlsx', 'collapse_button', 'expand_button', 'item', 'flat', 'generate_email', 'email_address'],
  components: {
  },
  computed: {
    ...mapGetters({
      settings: 'getGeneralSettings',
      services: 'getServicesSelectWithoutAll'
    }),
    navWidth() {
      if (this.$vuetify.breakpoint.xs) {
        return '80%'
      }

      if (this.$vuetify.breakpoint.sm) {
        return '60%'
      }

      return '35%'
    },
    service_name() {
      if (this.item.all_service) {
        return this.settings?.restructuring_services_all?.value || '[[Перелік послуг не вказаний]]'
      } else {
        return ((this.services || []).find(i => i.value === (this.item.service_id || 0)) || {}).text || '[[Послуга не вказана]]'
      }
    },
    debt_pay_day() {
      return this.settings?.restructuring_day_line?.value || 20
    },
    director() {
      return ((this.print_data?.response_people || []).find(i => i.category === 'director') || {}).full_name
    }
  },
  data() {
    return {
      showExpand: false,
      x: 0,
      y: 0,
      expand: null,
      zoom: 1,
      pages: 0,
      pages_data: {},
      filters: {
        city: null,
        streets: [],
        buildings: [],
        checker: null
      },
      split_height: 0,
      zoom_width: LANDSCAPE_ZOOM_WIDTH,
      services_columns: {},
      max_lvl: 0,
      by_grouping: false,
      items: [],
      grouping: [
      ],
      setting_dialog: this.global_settings,
      current_date: this.getCurrentDate(),
      settings_watcher: null,
      generate_watcher: null,
      generate_xlsx_watcher: null,
      generate_email_watcher: null,
      open_all_watcher: null,
      local_response_people: this.print_data?.response_people ? this.print_data.response_people.slice() : [],
    }
  },
  methods: {
    onCheckerChange(payload) {
      this.filters.checker = payload.value || null
    },
    onNavInput(e) {
      if (!e) {
        this.$emit('closeSettings')
      }
    },
    formatDate,
    getCurrentDate,
    getPrintDateRange,
    isExpanded,
    toggleExpand(level) {
      let lvl = 0
      lvl = level === -1
          ? this.expand ? this.max_lvl : 0
          : level

      const arr = this.expand ? [...Array(lvl+1).keys()] : [...Array(this.max_lvl+1).keys()].filter(i => i >= lvl).reverse()
      arr.forEach(el => {
        this.toggleExpand_one(el)
      })
    },
    toggleExpand_one(level) {
      const selector = `[data-level="level-${level}"]`
      document.querySelectorAll(selector).forEach(el => {
        const el_class = el.className
        const button_id = el.dataset.button
        if (el_class.indexOf('details') === -1) {
          const btn = document.getElementById(button_id)
          const close = btn.classList.contains('mdi-plus')

          if (this.expand) {
            if (close) {
              this.showRows(el_class, button_id)
            }
          } else {
            if (!close) {
              this.showRows(el_class, button_id)
            }
          }
        }
      })
    },
    pushUp(payload) {
      let current_index = this.grouping.indexOf(payload)
      // let arr_length = this.grouping.length - 1
      let new_arr = []

      if (current_index === 0) {
        new_arr = [...this.grouping.slice(1).concat([payload])]
      }
      //
      // if (current_index === 1) {
      //   new_arr = [...this.grouping.slice(1).concat(this.grouping.slice(0,1))]
      // }

      if (current_index >= 1) {
        new_arr = [
            ...this.grouping.slice(0, current_index - 1).concat([payload])
            .concat(this.grouping.slice(current_index-1, current_index))
            .concat(this.grouping.slice(current_index+1))
        ]
      }
      this.grouping = [...new_arr]
    },
    pushDown(payload) {
      let current_index = this.grouping.indexOf(payload)
      let arr_length = this.grouping.length - 1
      let new_arr = []

      if (current_index === arr_length) {
        new_arr = [...[payload].concat(this.grouping.slice(0, current_index))]
      } else {
        new_arr = [
            ...this.grouping.slice(0, current_index)
            .concat(this.grouping.slice(current_index + 1, current_index + 2))
            .concat(this.grouping.slice(current_index, current_index + 1))
            .concat(this.grouping.slice(current_index + 2))
        ]
      }

      this.grouping = [...new_arr]
    },
    getCollapseIcon(level) {
      return level < this.max_lvl - 1 ? 'mdi-minus' : 'mdi-plus'
    },
    showRows(group_class, button_id) {
      const button = document.getElementById(button_id)
      const close = button.classList.contains('mdi-plus')
      const groups = document.getElementsByClassName(group_class)
      const group_split = group_class.split(' ')
      let group_without = []

      Array.from(groups).forEach(el => {
        const cls_name = el.className.split(' ')
        if (close) {
          if (cls_name.length === group_split.length + 1) {
            if (!group_without.includes(el)) {
              group_without.push(el)
            }
          }
        } else {
          if (el.className.trim() !== group_class.trim()) {
            if (!group_without.includes(el)) {
              group_without.push(el)
            }
          }
        }
      })

      if (close) {
        button.classList.remove('mdi-plus')
        button.classList.add('mdi-minus')
        group_without.forEach(el => {
          el.style.display = ''
        })
      } else {
        button.classList.remove('mdi-minus')
        button.classList.add('mdi-plus')
        group_without.forEach(el => {
          el.style.display = 'none'
          Array.from(el.getElementsByTagName('button')).forEach(btn => {
            btn.classList.remove('mdi-minus')
            btn.classList.add('mdi-plus')
          })
        })
      }
    },
    generate_xlsx_file() {
      if (this.date_start && this.date_end) {
        const payload = {item_id: this.item.id}
        reportAPI.restructuring_agreement_xlsx(payload)
            .then(response => {
              const url = window.URL.createObjectURL(new Blob([response.data]))
              const link = document.createElement('a');
              const file_name = `restructuring_agreement${formatDate(this.item.date, 'DD_MM_YYYY')}.xlsx`
              link.href = url;
              link.setAttribute('download', file_name); //or any other extension
              document.body.appendChild(link);
              link.click();
            })
            .catch(err => {
              const error = err.response.data.detail;
              this.$store.dispatch(ALERT_SHOW, {text: error, color: 'error lighten-1'})
            })
            .finally(() => {
              this.$emit('generate_xlsx')
            })
      }
    },
    generate_report() {
      this.$emit('generate_report', !!this.items)
      this.$emit('report_loading_status', false)
    },
    generate_email_send() {
        this.$emit('email_loading_status', true)
        const payload = {
          item_id: this.item.id,
          email: this.email_address
        }
        reportAPI.restructuring_agreement_email(payload)
            .then(response => response.data)
            .then(data => {
              this.$emit('email_loading_status', false)
              this.$store.dispatch(ALERT_SHOW, {color: data.status === 'OK' ? 'success' : 'error', text: data.text})
            })
            .catch(err => {
              const error = err.response.data.detail;
              this.$emit('email_loading_status', false)
              this.$store.dispatch(ALERT_SHOW, {text: error, color: 'error lighten-1'})
            })
            .finally(() => {
              this.$emit('generate_email')
            })
    },
    getTotal(col) {
      return this.items.filter(item => item.level === 0).reduce((acc, r) => {
        return acc + r[col]
      }, 0)
    },
    getOnFullPage() {
      let width = 12
      const first_row = document.getElementById('header')
      first_row.childNodes.forEach(item => width += (item.clientWidth || 0))
      const gen_element = document.getElementsByClassName('report')[0]
      const scrollWidth = width * this.zoom
      const client_width = gen_element.clientWidth * this.zoom
      let zoom_width = 0
      let zoom = client_width / scrollWidth
      if (zoom === 0) zoom = 1
      if (zoom > 1) {
        zoom = 1
        zoom_width = LANDSCAPE_ZOOM_WIDTH
      }

      if (zoom !== 1) {
        zoom_width = 1 / zoom * LANDSCAPE_ZOOM_WIDTH
      }

      this.zoom = zoom
      this.zoom_width = zoom_width
    },
    closeSettings() {
      this.setting_dialog = false
      this.$emit('closeSettings')
    },
    watch_settings() {
      this.settings_watcher = this.$watch(
          'global_settings',
          {
            handler(payload) {
              this.setting_dialog = payload
            }
          }
      )
    },
    watch_generate() {
      this.settings_watcher = this.$watch(
          'generate',
          {
            handler(payload) {
              if (payload) {
                this.generate_report()
              }
            }
          }
      )
    },
    watch_generate_xlsx() {
      this.generate_xlsx_watcher = this.$watch(
          'generate_xlsx',
          {
            handler(payload) {
              if (payload) {
                this.generate_xlsx_file()
              }
            }
          }
      )
    },
    watch_generate_email() {
      this.generate_email_watcher = this.$watch(
          'generate_email',
          {
            handler(payload) {
              if (payload) {
                this.generate_email_send()
              }
            }
          }
      )
    },
    watch_open_all() {
      this.open_all_watcher = this.$watch(
          'open_all',
          {
            handler(payload) {
              if (payload !== undefined) {
                this.showExpand = false
                const el = payload ? this.expand_button.$el : this.collapse_button.$el
                const cord = el.getBoundingClientRect()
                this.expand = payload
                this.x = cord.left
                this.y = cord.bottom + 2
                this.$nextTick(() => {
                  this.showExpand = true
                })
                this.$emit('clearOpenAll')
              }
            }
          }
      )
    },
  },
  created() {
    this.watch_settings()
    this.watch_generate()
    this.watch_generate_xlsx()
    this.watch_open_all()
    this.watch_generate_email()

    this.$emit('generate_report', !!this.items)
    this.$emit('report_loading_status', false)

    // reportAPI.report_settings("statement_building_list_info")
    //     .then(response => response.data)
    //     .then(data => {
    //       const server_filters = JSON.parse(data)
    //       Object.keys(this.filters).forEach(key => {
    //         if (server_filters[key] !== undefined) {
    //           this.filters[key] = server_filters[key]
    //         }
    //       })
    //       if (server_filters['grouping'] && this.grouping) {
    //         this.grouping.forEach(g => {
    //           g.on = !!server_filters.grouping.includes(g.value);
    //         })
    //       }
    //     })
  },
  beforeDestroy() {
    if (this.settings_watcher) {
      this.settings_watcher()
    }
    if (this.generate_watcher) {
      this.generate_watcher()
    }
    if (this.open_all_watcher) {
      this.open_all_watcher()
    }
    if (this.generate_xlsx_watcher) {
      this.generate_xlsx_watcher()
    }
    if (this.generate_email_watcher) {
      this.generate_email_watcher()
    }
  }
}
</script>

<style lang="scss" scoped>
$border-color: #cbcbcb;

table, th, td {
  border: 1px solid #cbcbcb;
  border-collapse: collapse;
}

th {
  background: #f3f3f3;
  font-weight: 500 !important;
}
th, td {
  padding: 5px;
  font-size: 11px;
  font-weight: 400;
  width: 140px;
}
th.grow, td.grow {
  width: 240px;
  min-width: 240px;
}
tbody {
  tr {
    td {
      text-align: center;

      &:nth-child(1) {
        text-align: left;
        padding-left: 14px;
      }
    }
  }
}
:deep(td button:focus) {
  background: none !important;
}
.bg-level-0 {
  background: #e2e2e2;
}
.bg-level-1 {
  background: #f4f4f4;
}
.bg-level-2 {
  background: #f6f6f6;
}
.bg-level-3 {
  background: #f8f8f8;
}

.page-a4-landscape-auto {
  width: 1160px !important;
  position: relative;
}

.report-table {
  .report-table-row {
    line-height: 18px;
    min-height: auto !important;

    &:nth-child(1) {
      border-top: 1px solid $border-color;
    }

    & > div {
      border-right: 1px solid $border-color;
      border-bottom: 1px solid $border-color;
    }

    & > div:nth-child(1) {
      border-left: 1px solid $border-color;
    }
  }
}


@media print {
  * {
    float: none !important;
    clear: both;
  }

  .pages {
    height: auto !important;
    overflow: visible !important;
  }

  .page {
    visibility: visible !important;
    margin: 0 2px !important;
  }

  @page {
    margin: 0 0 5mm 0 !important;
  }
}

</style>